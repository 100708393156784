var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "report", staticClass: "report" },
    [
      _c(
        "fb-header",
        { attrs: { slot: "header", fixed: "" }, slot: "header" },
        [
          _c(
            "div",
            { staticClass: "head-left", attrs: { slot: "left" }, slot: "left" },
            [
              _c("fb-button", {
                attrs: { icon: "back", size: "small", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.backToNative()
                  },
                },
              }),
              _c("div", { staticClass: "collect-box" }, [
                _vm._v(_vm._s(_vm.reportName || _vm.tempTitle)),
              ]),
              _c("div", { staticClass: "relate-case" }, [
                _vm._v(_vm._s(_vm.relateCaseName)),
              ]),
            ],
            1
          ),
          _c("div", { attrs: { slot: "right" }, slot: "right" }, [
            _c("div", { staticClass: "header-menu" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.userName,
                      expression: "userName",
                    },
                  ],
                  staticClass: "menu-text",
                  on: { click: _vm.handleShare },
                },
                [_vm._v("分享")]
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "search-report" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.hasFirstPage,
                expression: "hasFirstPage",
              },
            ],
            staticClass: "print-fengmian",
            attrs: { id: "fengmian" },
          },
          [
            _c("div", { staticClass: "fengmian-logo" }),
            _c("div", { staticClass: "fengmian-lawfirm center" }, [
              _vm._v(_vm._s(this.lawFirmName)),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "fengmian-userinfo" }, [
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(this.userName)),
              ]),
              _c("span", [_vm._v("    ")]),
              _c("span", { staticClass: "tiem" }, [
                _vm._v(_vm._s(this.dayTime)),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "print-body", attrs: { id: "reportBody" } },
          [
            _vm.textReport
              ? _c("div", { staticClass: "text-content" }, [
                  _vm._v(_vm._s(_vm.textReport)),
                ])
              : _vm._e(),
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.judgeList.length,
                    expression: "judgeList.length",
                  },
                ],
                attrs: { id: "judge" },
              },
              [_vm._v("判例")]
            ),
            _vm._l(_vm.judgeList, function (item, index) {
              return _c(
                "div",
                {
                  key: item.doc_id,
                  staticClass: "item van-hairline--top-bottom",
                  attrs: { id: item.doc_id },
                },
                [
                  _c("judgementItem", {
                    ref: "judgeitem",
                    refInFor: true,
                    attrs: { itemindex: index, item: item, isPreview: true },
                    on: { toDetail: _vm.toJudgeDetail },
                  }),
                ],
                1
              )
            }),
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.lawList.length,
                    expression: "lawList.length",
                  },
                ],
                attrs: { id: "law" },
              },
              [_vm._v("法律")]
            ),
            _vm._l(_vm.lawList, function (item, index) {
              return _c(
                "div",
                {
                  key: item.law_id,
                  staticClass: "item van-hairline--top-bottom",
                  attrs: { id: item.law_id },
                },
                [
                  _c("lawItem", {
                    ref: "lawitem",
                    refInFor: true,
                    attrs: {
                      itemindex: index,
                      item: item,
                      isPreview: true,
                      contentType: "lawSearch",
                    },
                    on: { toDetail: _vm.toLawDetail },
                  }),
                ],
                1
              )
            }),
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.knowledgeList.length,
                    expression: "knowledgeList.length",
                  },
                ],
                attrs: { id: "knowledge" },
              },
              [_vm._v("知识")]
            ),
            _vm._l(_vm.knowledgeList, function (item) {
              return _c(
                "div",
                {
                  key: item.url,
                  staticClass: "item van-hairline--top-bottom",
                  attrs: { id: item.url },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        overflow: "hidden",
                        "padding-bottom": "10px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toKnowledgeDetail(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _c("div", {
                          staticClass: "text",
                          domProps: { innerHTML: _vm._s(item.title) },
                        }),
                      ]),
                      _vm._l(item.content, function (iter, num) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: iter.value,
                                expression: "iter.value",
                              },
                            ],
                            key: num,
                            staticClass: "zkcontent",
                          },
                          [
                            _c("span", {
                              domProps: { innerHTML: _vm._s(iter.value) },
                            }),
                          ]
                        )
                      }),
                      _c("div", { staticClass: "showrow" }, [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(item.origin) + _vm._s(" - " + item.pub_date)
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]
              )
            }),
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.criterionList.length,
                    expression: "criterionList.length",
                  },
                ],
                attrs: { id: "criterion" },
              },
              [_vm._v("标准")]
            ),
            _vm._l(_vm.criterionList, function (item) {
              return _c(
                "div",
                {
                  key: item.nsId,
                  staticClass: "item van-hairline--top-bottom",
                  attrs: { id: item.nsId },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        overflow: "hidden",
                        "padding-bottom": "10px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toCriterionDetail(item.url)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _c("div", { staticClass: "text" }, [
                          _c("span", { staticClass: "CanNot" }, [
                            _vm._v(" " + _vm._s(item.stdStatus) + " "),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(item.id + " ") +
                              _vm._s(item.stdTitle) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "showrow" }, [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            _vm._s(item.department + " ") +
                              _vm._s(item.publishDate + " ") +
                              _vm._s(item.execDate)
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.agreementList.length,
                    expression: "agreementList.length",
                  },
                ],
                attrs: { id: "agreement" },
              },
              [_vm._v("合同")]
            ),
            _vm._l(_vm.agreementList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "item van-hairline--top-bottom",
                  attrs: { id: item.id },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        overflow: "hidden",
                        "padding-bottom": "10px",
                      },
                    },
                    [
                      _c("div", { staticClass: "item-title" }, [
                        _c("div", { staticClass: "text" }, [
                          item.isRecommend
                            ? _c("span", { staticClass: "CanNot" }, [
                                _vm._v(" 推荐 "),
                              ])
                            : _vm._e(),
                          _vm._v(" " + _vm._s(item.title) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "showrow" }, [
                        _c("div", { staticClass: "text" }, [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.publisher && item.publisher.nickname
                                )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.createTime && _vm.fmTime(item.createTime)
                                )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.price === 0 || item.price === "0"
                                    ? "免费"
                                    : item.price + "元"
                                )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                item.commentCount !== ""
                                  ? " 下载量: (" + item.commentCount + ") "
                                  : ""
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              )
            }),
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.contentList.length + _vm.anaList.length,
                    expression: "contentList.length + anaList.length",
                  },
                ],
                attrs: { id: "content" },
              },
              [_vm._v("正文")]
            ),
            _c(
              "div",
              [
                _vm._l(_vm.contentList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: `content-${index}`,
                      staticClass: "content-item",
                      attrs: { id: `content-${item.id}` },
                    },
                    [
                      _c("h3", { staticClass: "content-title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      _vm._l(item.arr, function (ite, ind) {
                        return _c(
                          "div",
                          { key: ind },
                          [
                            _c(
                              "h4",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: ite.name !== "正文" && item.isLaw,
                                    expression:
                                      "ite.name !== '正文' && item.isLaw",
                                  },
                                ],
                              },
                              [_vm._v(_vm._s(ite.name))]
                            ),
                            _c("p", {
                              domProps: { innerHTML: _vm._s(ite.text) },
                            }),
                            _vm._l(ite.arr, function (v, i) {
                              return _c("div", { key: i }, [
                                _c("p", {
                                  domProps: { innerHTML: _vm._s(v.text) },
                                }),
                              ])
                            }),
                          ],
                          2
                        )
                      }),
                      _vm._l(item.textArr, function (ite, idx) {
                        return _c("div", { key: `${index}-${idx}` }, [
                          typeof item === "string"
                            ? _c("p", { domProps: { innerHTML: _vm._s(ite) } })
                            : _c("div", [
                                _c("h4", [_vm._v(_vm._s(ite.name))]),
                                _c("p", {
                                  domProps: { innerHTML: _vm._s(ite.text) },
                                }),
                              ]),
                        ])
                      }),
                    ],
                    2
                  )
                }),
                _vm._l(_vm.anaList, function (item, index) {
                  return _c(
                    "div",
                    { key: `ana-${index}`, staticClass: "content-item" },
                    [
                      item.type === "law"
                        ? _c(
                            "div",
                            {
                              staticClass: "content-item",
                              attrs: { id: item.id },
                            },
                            [
                              _c("h3", { staticClass: "content-title" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                              _vm._l(item.anaArr, function (ite, ind) {
                                return _c("div", { key: ind }, [
                                  _c("h4", [
                                    _vm._v(" " + _vm._s(ite.name) + " "),
                                  ]),
                                  _c("p", {
                                    domProps: { innerHTML: _vm._s(ite.text) },
                                  }),
                                ])
                              }),
                            ],
                            2
                          )
                        : item.type === "appeal"
                        ? _c(
                            "div",
                            {
                              staticClass: "content-item",
                              attrs: { id: item.id },
                            },
                            [
                              _c("h3", { staticClass: "content-title" }, [
                                _c("span", [_vm._v(_vm._s(item.case_name))]),
                                _c("span", [_vm._v(_vm._s(item.case_id))]),
                              ]),
                              _vm._l(item.data, function (ite) {
                                return _c(
                                  "div",
                                  { key: ite.title },
                                  [
                                    _c("h4", [
                                      _vm._v(" " + _vm._s(ite.title) + " "),
                                    ]),
                                    _vm._l(ite.data, function (it) {
                                      return _c("p", { key: it.toString() }, [
                                        _vm._v(" " + _vm._s(it) + " "),
                                      ])
                                    }),
                                  ],
                                  2
                                )
                              }),
                            ],
                            2
                          )
                        : item.type === "deem"
                        ? _c(
                            "div",
                            {
                              staticClass: "content-item",
                              attrs: { id: item.id },
                            },
                            [
                              _c("h3", { staticClass: "content-title" }, [
                                _c("span", [_vm._v(_vm._s(item.case_name))]),
                                _c("span", [_vm._v(_vm._s(item.case_id))]),
                              ]),
                              _c("p", [_vm._v(_vm._s(item.text))]),
                            ]
                          )
                        : item.type === "focus"
                        ? _c(
                            "div",
                            {
                              staticClass: "content-item",
                              attrs: { id: item.id },
                            },
                            [
                              _c("p", {
                                domProps: { innerHTML: _vm._s(item.title) },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
              ],
              2
            ),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPanel,
              expression: "showPanel",
            },
          ],
          staticClass: "bottom-menu",
        },
        [
          _c("div", { staticClass: "menu-content" }, [
            _c(
              "div",
              {
                staticStyle: {
                  padding: "10px",
                  "marging-bottom": "10px",
                  "text-align": "right",
                },
              },
              [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        _vm.showPanel = false
                      },
                    },
                  },
                  [_vm._v("关闭")]
                ),
              ]
            ),
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasFirstPage,
                    expression: "hasFirstPage",
                  },
                ],
                attrs: { id: "fengmianTittle" },
                on: {
                  click: function ($event) {
                    return _vm.handleSwith("fengmian", "fengmianTittle")
                  },
                },
              },
              [_vm._v("封面")]
            ),
            _c(
              "h3",
              {
                attrs: { id: "reportBodyTitle" },
                on: {
                  click: function ($event) {
                    return _vm.handleSwith("reportBody", "reportBodyTitle")
                  },
                },
              },
              [_vm._v("报告正文")]
            ),
            _c(
              "div",
              { staticClass: "report-mulu" },
              [
                _c(
                  "van-collapse",
                  {
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c(
                      "van-collapse-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.judgeList.length,
                            expression: "judgeList.length",
                          },
                        ],
                        attrs: { name: "1" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "h3",
                                  {
                                    attrs: { id: "judgeTitle" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSwith(
                                          "judge",
                                          "judgeTitle"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("判例")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      _vm._l(_vm.judgeList, function (item, index1) {
                        return _c(
                          "div",
                          {
                            key: item.doc_id,
                            style: _vm.lineTitleStyle,
                            attrs: { id: `judge-${index1}` },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleSwith(
                                  item.doc_id,
                                  `judge-${index1}`
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.case_name ? item.case_name : "") +
                                " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "van-collapse-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.lawList.length,
                            expression: "lawList.length",
                          },
                        ],
                        attrs: { name: "2" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "h3",
                                  {
                                    attrs: { id: "lawTitle" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSwith(
                                          "law",
                                          "lawTitle"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("法律")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      _vm._l(_vm.lawList, function (item, index2) {
                        return _c(
                          "div",
                          {
                            key: item.law_id,
                            style: _vm.lineTitleStyle,
                            attrs: { id: `law-${index2}` },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleSwith(
                                  item.law_id,
                                  `law-${index2}`
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(item.title ? item.title : "") + " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "van-collapse-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.knowledgeList.length,
                            expression: "knowledgeList.length",
                          },
                        ],
                        attrs: { name: "3" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "h3",
                                  {
                                    attrs: { id: "knowledgeTitle" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSwith(
                                          "knowledge",
                                          "knowledgeTitle"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("知识")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      _vm._l(_vm.knowledgeList, function (item, index3) {
                        return _c(
                          "div",
                          {
                            key: item.url,
                            style: _vm.lineTitleStyle,
                            attrs: { id: `knowledge-${index3}` },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleSwith(
                                  item.url,
                                  `knowledge-${index3}`
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(item.title ? item.title : "") + " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "van-collapse-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.criterionList.length,
                            expression: "criterionList.length",
                          },
                        ],
                        attrs: { name: "4" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "h3",
                                  {
                                    attrs: { id: "criterionTitle" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSwith(
                                          "criterion",
                                          "criterionTitle"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("标准")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      _vm._l(_vm.criterionList, function (item, index4) {
                        return _c(
                          "div",
                          {
                            key: item.nsId,
                            style: _vm.lineTitleStyle,
                            attrs: { id: `criterion-${index4}` },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleSwith(
                                  item.nsId,
                                  `criterion-${index4}`
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.stdTitle ? item.stdTitle : "") +
                                " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "van-collapse-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.agreementList.length,
                            expression: "agreementList.length",
                          },
                        ],
                        attrs: { name: "6" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "h3",
                                  {
                                    attrs: { id: "agreementTitle" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSwith(
                                          "agreement",
                                          "agreementTitle"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("合同")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      _vm._l(_vm.agreementList, function (item, index7) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            style: _vm.lineTitleStyle,
                            attrs: { id: `agreement-${index7}` },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleSwith(
                                  item.id,
                                  `agreement-${index7}`
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(item.title ? item.title : "") + " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "van-collapse-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.contentList.length,
                            expression: "contentList.length",
                          },
                        ],
                        attrs: { name: "5" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "h3",
                                  {
                                    attrs: { id: "contentTitle" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSwith(
                                          "content",
                                          "contentTitle"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("正文")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _vm._l(_vm.contentList, function (item, index5) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              style: _vm.lineTitleStyle,
                              attrs: { id: `content-${index5}` },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleSwith(
                                    `content-${item.id}`,
                                    `content-${index5}`
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(item.title ? item.title : "") + " "
                              ),
                            ]
                          )
                        }),
                        _vm._l(_vm.anaList, function (item, index6) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              style: _vm.lineTitleStyle,
                              attrs: { id: `ana-${index6}` },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.handleSwith(
                                    `${item.id}`,
                                    `ana-${index6}`
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.title.length > 15
                                      ? item.title.substr(0, 15) + "..."
                                      : item.title
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.detailshow.show
        ? _c(
            "div",
            { staticClass: "nav", attrs: { id: "nav" } },
            [
              _c("lawDetail", {
                attrs: {
                  id: _vm.detailshow.case_id,
                  title: _vm.detailshow.title,
                  catlog: _vm.detailshow.catlog,
                  words: _vm.detailshow.words,
                },
                on: { changeflag: _vm.changeflag },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.detailshow.flag
        ? _c(
            "div",
            { staticClass: "nav", attrs: { id: "nav" } },
            [
              _c("percentDetail", {
                attrs: {
                  id: _vm.detailshow.case_id,
                  words: _vm.detailshow.words,
                  titleName: _vm.detailshow.name,
                },
                on: { changeflag: _vm.changeshow },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "bottom-wrapper" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showPanel,
                expression: "!showPanel",
              },
            ],
            staticClass: "open-panel",
            on: {
              click: function ($event) {
                _vm.showPanel = true
              },
            },
          },
          [_vm._v("目录")]
        ),
      ]),
      _vm.favVisible
        ? _c(
            "div",
            { staticClass: "full-screen-portal-comp" },
            [
              _c("skyDrive", {
                attrs: {
                  listid: _vm.favFolderId,
                  Type: "SEARCHREPORT",
                  modulename: "分析报告",
                  listname: "检索报告",
                  summary: _vm.summary,
                },
                on: { CancelCollect: _vm.cancelFav, getFold: _vm.useFav },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fengmian-report" }, [
      _c("span", [_vm._v("检")]),
      _c("span", [_vm._v("索")]),
      _c("span", [_vm._v("报")]),
      _c("span", [_vm._v("告")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }