<template>
  <div class="report" ref="report">
    <!-- 头部插槽 -->
    <fb-header slot="header" fixed>
      <div slot="left" class="head-left">
        <fb-button icon="back" @click="backToNative()" size="small" type="primary"></fb-button>
        <div class="collect-box">{{ reportName || tempTitle }}</div>
        <div class="relate-case">{{ relateCaseName }}</div>
      </div>
      <div slot="right">
        <div class="header-menu">
          <!-- <div class="menu-text" @click="handleAddFirstPage">
            插入封面
          </div>
          <div class="menu-text" @click="handleSave">
            保存
          </div> -->
          <div class="menu-text" @click="handleShare" v-show="userName">分享</div>
          <!--  <div class="menu-text" @click="handleDownLoad">
            下载
          </div> -->
        </div>
      </div>
    </fb-header>
    <div class="search-report">
      <div class="print-fengmian" id="fengmian" v-show="hasFirstPage">
        <div class="fengmian-logo"></div>
        <div class="fengmian-lawfirm center">{{ this.lawFirmName }}</div>
        <div class="fengmian-report">
          <span>检</span>
          <span>索</span>
          <span>报</span>
          <span>告</span>
        </div>
        <div class="fengmian-userinfo">
          <span class="name">{{ this.userName }}</span>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span class="tiem">{{ this.dayTime }}</span>
        </div>
      </div>
      <div class="print-body" id="reportBody">
        <div class="text-content" v-if="textReport">{{ textReport }}</div>
        <h3 id="judge" v-show="judgeList.length">判例</h3>
        <div class="item van-hairline--top-bottom" v-for="(item, index) in judgeList" :key="item.doc_id" :id="item.doc_id">
          <judgementItem ref="judgeitem" :itemindex="index" :item="item" :isPreview="true" @toDetail="toJudgeDetail" />
          <!-- <mJudgeItem
            ref="judgeitem"
            :itemindex="index"
            :item="item"
            @toDetail="toJudgeDetail"
          /> -->
        </div>
        <h3 id="law" v-show="lawList.length">法律</h3>
        <div class="item van-hairline--top-bottom" v-for="(item, index) in lawList" :key="item.law_id" :id="item.law_id">
          <lawItem ref="lawitem" :itemindex="index" :item="item" :isPreview="true" contentType="lawSearch" @toDetail="toLawDetail" />
          <!-- <mlawItem
            ref="lawitem"
            :itemindex="index"
            :item="item"
            @toDetail="toLawDetail"
          /> -->
        </div>
        <h3 id="knowledge" v-show="knowledgeList.length">知识</h3>
        <div class="item van-hairline--top-bottom" v-for="item in knowledgeList" :key="item.url" :id="item.url">
          <!-- <Item
            ref="knowledgeitem"
            :itemindex="index"
            :item="item"
            :isPreview="true"
            contentType="knowledgeSearch"
            @toDetail="toKnowledgeDetail"
          /> -->
          <!-- 知识 -->
          <div style="overflow: hidden; padding-bottom: 10px" @click="toKnowledgeDetail(item)">
            <div class="item-title">
              <div class="text" v-html="item.title"></div>
            </div>
            <div class="zkcontent" v-for="(iter, num) in item.content" v-show="iter.value" :key="num">
              <span v-html="iter.value"></span>
            </div>
            <div class="showrow">
              <div class="text">{{ item.origin }}{{ ' - ' + item.pub_date }}</div>
            </div>
          </div>
        </div>
        <h3 id="criterion" v-show="criterionList.length">标准</h3>
        <div class="item van-hairline--top-bottom" v-for="item in criterionList" :key="item.nsId" :id="item.nsId">
          <!-- <Item
            ref="criterionitem"
            :itemindex="index"
            :item="item"
            :isPreview="true"
            contentType="criterionSearch"
            @toDetail="toCriterionDetail"
          /> -->
          <div style="overflow: hidden; padding-bottom: 10px" @click="toCriterionDetail(item.url)">
            <div class="item-title">
              <div class="text">
                <span class="CanNot">
                  {{ item.stdStatus }}
                </span>
                {{ item.id + ' ' }}{{ item.stdTitle }}
              </div>
            </div>
            <div class="showrow">
              <div class="text">{{ item.department + ' ' }}{{ item.publishDate + ' ' }}{{ item.execDate }}</div>
            </div>
          </div>
        </div>
        <h3 id="agreement" v-show="agreementList.length">合同</h3>
        <div class="item van-hairline--top-bottom" v-for="item in agreementList" :key="item.id" :id="item.id">
          <div style="overflow: hidden; padding-bottom: 10px">
            <div class="item-title">
              <div class="text">
                <span class="CanNot" v-if="item.isRecommend"> 推荐 </span>
                {{ item.title }}
              </div>
            </div>
            <div class="showrow">
              <div class="text">
                <span> {{ item.publisher && item.publisher.nickname }}</span>
                <span> {{ item.createTime && fmTime(item.createTime) }}</span>
                <span> {{ item.price === 0 || item.price === '0' ? '免费' : item.price + '元' }}</span>
                <span>{{ item.commentCount !== '' ? ' 下载量: (' + item.commentCount + ') ' : '' }}</span>
              </div>
            </div>
          </div>
        </div>
        <h3 id="content" v-show="contentList.length + anaList.length">正文</h3>
        <div>
          <div class="content-item" v-for="(item, index) in contentList" :id="`content-${item.id}`" :key="`content-${index}`">
            <h3 class="content-title">{{ item.title }}</h3>
            <div v-for="(ite, ind) in item.arr" :key="ind">
              <h4 v-show="ite.name !== '正文' && item.isLaw">{{ ite.name }}</h4>
              <p v-html="ite.text"></p>
              <div v-for="(v, i) in ite.arr" :key="i">
                <p v-html="v.text"></p>
              </div>
            </div>
            <div v-for="(ite, idx) in item.textArr" :key="`${index}-${idx}`">
              <p v-if="typeof item === 'string'" v-html="ite"></p>
              <div v-else>
                <h4>{{ ite.name }}</h4>
                <p v-html="ite.text"></p>
              </div>
            </div>
          </div>
          <div class="content-item" v-for="(item, index) in anaList" :key="`ana-${index}`">
            <div class="content-item" :id="item.id" v-if="item.type === 'law'">
              <h3 class="content-title">{{ item.title }}</h3>
              <div v-for="(ite, ind) in item.anaArr" :key="ind">
                <h4>
                  {{ ite.name }}
                </h4>
                <p v-html="ite.text"></p>
              </div>
            </div>
            <div class="content-item" :id="item.id" v-else-if="item.type === 'appeal'">
              <h3 class="content-title">
                <span>{{ item.case_name }}</span>
                <span>{{ item.case_id }}</span>
              </h3>
              <div v-for="ite in item.data" :key="ite.title">
                <h4>
                  {{ ite.title }}
                </h4>
                <p v-for="it in ite.data" :key="it.toString()">
                  {{ it }}
                </p>
              </div>
            </div>
            <div class="content-item" :id="item.id" v-else-if="item.type === 'deem'">
              <h3 class="content-title">
                <span>{{ item.case_name }}</span>
                <span>{{ item.case_id }}</span>
              </h3>
              <p>{{ item.text }}</p>
            </div>
            <div class="content-item" :id="item.id" v-else-if="item.type === 'focus'">
              <p v-html="item.title"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-menu" v-show="showPanel">
      <div class="menu-content">
        <div style="padding: 10px; marging-bottom: 10px; text-align: right">
          <span @click="showPanel = false">关闭</span>
        </div>
        <h3 v-show="hasFirstPage" @click="handleSwith('fengmian', 'fengmianTittle')" id="fengmianTittle">封面</h3>
        <h3 @click="handleSwith('reportBody', 'reportBodyTitle')" id="reportBodyTitle">报告正文</h3>
        <div class="report-mulu">
          <van-collapse v-model="activeNames">
            <van-collapse-item name="1" v-show="judgeList.length">
              <template #title>
                <h3 @click="handleSwith('judge', 'judgeTitle')" id="judgeTitle">判例</h3>
              </template>
              <div v-for="(item, index1) in judgeList" :key="item.doc_id" :style="lineTitleStyle" :id="`judge-${index1}`" @click.stop="handleSwith(item.doc_id, `judge-${index1}`)">
                {{ item.case_name ? item.case_name : '' }}
              </div>
            </van-collapse-item>
            <van-collapse-item name="2" v-show="lawList.length">
              <template #title>
                <h3 @click="handleSwith('law', 'lawTitle')" id="lawTitle">法律</h3>
              </template>
              <div v-for="(item, index2) in lawList" :key="item.law_id" :style="lineTitleStyle" :id="`law-${index2}`" @click.stop="handleSwith(item.law_id, `law-${index2}`)">
                {{ item.title ? item.title : '' }}
              </div>
            </van-collapse-item>
            <van-collapse-item name="3" v-show="knowledgeList.length">
              <template #title>
                <h3 @click="handleSwith('knowledge', 'knowledgeTitle')" id="knowledgeTitle">知识</h3>
              </template>
              <div v-for="(item, index3) in knowledgeList" :key="item.url" :style="lineTitleStyle" :id="`knowledge-${index3}`" @click.stop="handleSwith(item.url, `knowledge-${index3}`)">
                {{ item.title ? item.title : '' }}
              </div>
            </van-collapse-item>
            <van-collapse-item name="4" v-show="criterionList.length">
              <template #title>
                <h3 @click="handleSwith('criterion', 'criterionTitle')" id="criterionTitle">标准</h3>
              </template>
              <div v-for="(item, index4) in criterionList" :key="item.nsId" :style="lineTitleStyle" :id="`criterion-${index4}`" @click.stop="handleSwith(item.nsId, `criterion-${index4}`)">
                {{ item.stdTitle ? item.stdTitle : '' }}
              </div>
            </van-collapse-item>
            <van-collapse-item name="6" v-show="agreementList.length">
              <template #title>
                <h3 @click="handleSwith('agreement', 'agreementTitle')" id="agreementTitle">合同</h3>
              </template>
              <div v-for="(item, index7) in agreementList" :key="item.id" :style="lineTitleStyle" :id="`agreement-${index7}`" @click.stop="handleSwith(item.id, `agreement-${index7}`)">
                {{ item.title ? item.title : '' }}
              </div>
            </van-collapse-item>
            <van-collapse-item name="5" v-show="contentList.length">
              <template #title>
                <h3 @click="handleSwith('content', 'contentTitle')" id="contentTitle">正文</h3>
              </template>
              <template>
                <div v-for="(item, index5) in contentList" :key="item.id" :style="lineTitleStyle" :id="`content-${index5}`" @click.stop="handleSwith(`content-${item.id}`, `content-${index5}`)">
                  {{ item.title ? item.title : '' }}
                </div>
              </template>
              <template>
                <div v-for="(item, index6) in anaList" :key="item.id" :style="lineTitleStyle" :id="`ana-${index6}`" @click.stop="handleSwith(`${item.id}`, `ana-${index6}`)">
                  {{ item.title.length > 15 ? item.title.substr(0, 15) + '...' : item.title }}
                </div>
              </template>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </div>
    <div class="nav" id="nav" v-if="detailshow.show">
      <lawDetail :id="detailshow.case_id" :title="detailshow.title" :catlog="detailshow.catlog" :words="detailshow.words" @changeflag="changeflag"></lawDetail>
    </div>
    <div class="nav" id="nav" v-if="detailshow.flag">
      <percentDetail :id="detailshow.case_id" :words="detailshow.words" :titleName="detailshow.name" @changeflag="changeshow"></percentDetail>
    </div>
    <div class="bottom-wrapper">
      <div class="open-panel" @click="showPanel = true" v-show="!showPanel">目录</div>
    </div>
    <div class="full-screen-portal-comp" v-if="favVisible">
      <skyDrive :listid="favFolderId" :Type="'SEARCHREPORT'" @CancelCollect="cancelFav" :modulename="'分析报告'" :listname="'检索报告'" :summary="summary" @getFold="useFav"></skyDrive>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
// import goBackMixin from '@mixins/goBack'
import judgementItem from './../components/judgementItem'
import lawItem from './../components/lawItem'
// import mJudgeItem from './../components/mJudgeItem'
// import mlawItem from './../components/mlawItem'
// import Item from './../components/item'
import skyDrive from '@/pages/skyDrive/index'
import percentDetail from '@/pages/precedentDetail'
import lawDetail from '@/pages/Search/pages/lawDetail'
export default {
  name: 'searchReport',
  // mixins: [goBackMixin],
  components: {
    judgementItem,
    lawItem,
    // Item,
    // mJudgeItem,
    // mlawItem,
    skyDrive,
    percentDetail,
    lawDetail,
  },
  data() {
    return {
      activeNames: ['1', '2', '3', '4', '5', '6'],
      showPanel: false,
      judgeList: [],
      lawList: [],
      criterionList: [],
      knowledgeList: [],
      contentList: [],
      agreementList: [],
      anaList: [],
      textReport: '',
      lawWords: [],
      judgeWords: [],
      hasFirstPage: false,
      lawFirmName: '',
      userName: '',
      avatarUrl: '',
      userId: '',
      dayTime: '',
      detailshow: {
        show: false,
        case_id: null,
        title: null,
        flag: false,
        showIpt: false,
        catlog: null,
        name: null,
        section: null,
      },
      lineTitleStyle: {
        height: '40px',
        'line-height': '40px',
        overflow: 'hidden',
        'white-space': 'nowrap',
        'text-overflow': 'ellipsis',
      },
      // 生成报告成功后 接口返回的数据
      savedReportData: null,
      reportId: null,
      // 收藏组件开关
      favVisible: false,
      // 收藏的文件夹id 用于查看报告
      favFolderId: 0,
      relateCaseName: '',
      reportName: '',
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'organization']),
    ...mapState('ana', ['text', 'summary', 'anaTwoKeyDown']),
    // userAgent
    ua() {
      return navigator.userAgent
    },
    tempTitle() {
      const today = new Date()
      const dayTime =
        today.getFullYear() +
        '.' +
        (today.getMonth() + 1) +
        '.' +
        today.getDate() +
        ' ' +
        (today.getHours() > 9 ? today.getHours() : '0' + today.getHours()) +
        ':' +
        (today.getMinutes() > 9 ? today.getMinutes() : '0' + today.getMinutes())
      const title = `${this.dayTime ? this.dayTime : dayTime}${this.userName}${this.judgeList.length > 0 ? this.judgeList.length + '判' : ''}${
        this.lawList.length > 0 ? this.lawList.length + '法' : ''
      }${this.knowledgeList.length > 0 ? this.knowledgeList.length + '知' : ''}${this.criterionList.length > 0 ? this.criterionList.length + '标' : ''}${
        this.agreementList.length > 0 ? this.agreementList.length + '合' : ''
      }${this.contentList.length + this.anaList.length > 0 ? this.contentList.length + this.anaList.length + '文' : ''}`
      // console.log('tempTitle--------------->', title)
      return title
    },
    appText() {
      return '下载app'
    },
  },
  created() {
    console.log(this.$route.params.reportId, 'query query query')
    this.userId = this.userInfo.id
    this.reportId = this.$route.params.reportId ? this.$route.params.reportId : null
    this.fetchReportDate(this.reportId)
  },
  mounted() {
    setTimeout(() => {
      const list = document.getElementsByClassName('vue-portal-target')
      for (var i = 0; i < list.length; i++) {
        list[i].style.display = 'none'
      }
      // window.addEventListener('scroll', this.handleScroll, true)
    }, 200)
  },

  methods: {
    ...mapMutations('searchReport', ['SET_JUDGE_REPORT', 'SET_LAW_REPORT']),
    // 格式化时间戳
    fmTime(unixtime) {
      var date = new Date(unixtime)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var timeStr = y + '-' + m + '-' + d + ' 发布'
      return timeStr
    },
    // 获取信息
    async fetchReportDate(id) {
      const { data } = await this.$axios.get(`${this.$base}/management/collection/${id}`)
      if (data.code === 200 && data.data) {
        const reportData = JSON.parse(data.data.content || '{}')
        console.log(reportData, '----------->>>reportData')
        this.judgeList = reportData.judgeReports || []
        this.lawList = reportData.lawReports || []
        this.contentList = reportData.contentReports || []
        this.criterionList = reportData.criterionReports || []
        this.agreementList = reportData.agreementReports || []
        this.knowledgeList = reportData.knowledgeReports || []
        this.anaList = reportData.anaReports || []
        this.textReport = reportData.textReport || ''
        this.judgeWords = reportData.judgeWords || []
        this.lawWords = reportData.lawWords || []
        this.userName = reportData.userName || ''
        this.lawFirmName = reportData.lawFirmName || ''
        this.dayTime = reportData.dayTime || ''
        this.avatarUrl = reportData.avatarUrl || ''
        this.hasFirstPage = !!reportData.hasFirstPage
        console.log(reportData, this.anaList, '-------------')
        this.reportName = data.data.name
        this.relateCaseName = data.data.label || '暂无关联案件'
        // 封面或报告正文选中
        setTimeout(() => {
          if (this.hasFirstPage) {
            document.getElementById('fengmianTittle').className = 'active-title'
          } else {
            document.getElementById('reportBodyTitle').className = 'active-title'
          }
        }, 200)
      }
    },
    backToNative() {
      const iOSUA = 'fb-iOS-Webview'
      const AndroidUA = 'fb-Android-Webview'
      const flutterUA = 'fb-flutter-Webview'

      const ua = navigator.userAgent
      if (ua === AndroidUA) {
        try {
          // eslint-disable-next-line
          AND2JS.back()
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === iOSUA) {
        try {
          // eslint-disable-next-line
          window.webkit.messageHandlers.back.postMessage({})
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === flutterUA) {
        try {
          // eslint-disable-next-line
          window.back.postMessage('{}')
        } catch (err) {
          this.$notify('没有可以返回的页面')
          console.log('back.postMessage', err)
        }
      } else {
        this.$router.back()
      }
    },
    changeshow() {
      this.detailshow.flag = false
    },
    changeflag() {
      this.detailshow.show = false
    },
    downloadApp() {
      window.open('https://sj.qq.com/myapp/detail.htm?apkName=com.farbun.fb')
    },
    toLawDetail(item) {
      const { law_id: id, hit_datas: hit } = item
      this.detailshow.case_id = id
      // 通过设置title为 第n条 以实现打开后跳转
      if (hit && hit.catlog && hit.catlog.length) {
        this.detailshow.title = hit.catlog[0]
      }
      this.detailshow.show = true
    },
    // 判例详情页面
    toJudgeDetail(payload, source) {
      console.log(payload, source, 'toDetail')
      // TODO 完善打开详情页代码
      if (typeof payload === 'object') {
        this.detailshow.title = payload.catlog
        this.detailshow.case_id = payload.docid
        this.detailshow.flag = true
      } else if (source === undefined) {
        this.detailshow.case_id = payload
        this.detailshow.show = true
      }
    },
    // 知识跳转页面
    toKnowledgeDetail(payload) {
      console.log('知识跳转页面', payload.url, payload.source)
      if (payload.source !== undefined) {
        this.postToNative(payload.url, payload.source)
      }
    },
    // 跳转到标准详情
    toCriterionDetail(url) {
      console.log('跳转到标准详情', url)
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.showNews(url)
      } else if (ua === this.$ua.i) {
        return this.$notify('苹果端不支持查看')
        // window.webkit.messageHandlers.showNews.postMessage(url)
      }
    },
    async toDetail(payload, source) {
      console.log(payload, source, 'toDetail')
      if (this.userInfo.memberStatus !== 1) {
        const url = `${this.$base}/management/users/${this.userInfo.id}`
        const res = await this.$axios.get(url)
        const { code, data } = res.data
        if (code === 200) {
          const temp = this.userInfo
          temp.memberStatus = data.memberStatus
          this.$store.commit('user/resumeLogin', {
            userInfo: temp,
          })
          if (data.memberStatus !== 1) {
            let secKind = 'panli'
            if (this.btnLeft === 0) {
              secKind = 'panli'
            } else if (this.btnLeft === 1) {
              secKind = 'falv'
            } else {
              secKind = 'zhishi'
            }
            return this.$bus.$emit('fb-member', {
              dialogVisible: true,
              kind: 'dashuju',
              secKind: secKind,
            })
          }
        }
      }
      // this.$emit('toDetail', payload, source)
      // TODO 完善打开详情页代码
      if (typeof payload === 'object') {
        this.detailshow.title = payload.catlog
        this.detailshow.case_id = payload.docid
        this.detailshow.flag = true
      } else if (source === undefined) {
        this.detailshow.case_id = payload
        this.detailshow.show = true
      } else {
        this.postToNative(payload, source)
      }
    },
    // 调用原生端方法
    postToNative(url, source) {
      setTimeout(() => {
        const ua = this.ua
        if (ua === this.$ua.a) {
          // eslint-disable-next-line no-undef
          AND2JS.showNews(url)
        } else if (ua === this.$ua.i) {
          window.webkit.messageHandlers.showNews.postMessage(url)
        }
      })
    },
    // 点击目录切换到指定的位置
    handleSwith(idName, currentIdName, name) {
      if (name === '正文') {
        console.log(idName, currentIdName)
        return
      }
      const lastActiveEle = document.getElementsByClassName('active-title')[0]
      const fbHeader = document.getElementsByClassName('fb-header')[0].offsetHeight
      const eleTopHeight = document.getElementById(idName).offsetTop
      const currentEle = document.getElementById(currentIdName)
      if (lastActiveEle) {
        lastActiveEle.className = ''
      }
      currentEle.className = 'active-title'
      document.body.scrollTo(0, eleTopHeight - fbHeader)
    },
    // 保存
    handleSave() {
      this.favVisible = true
    },
    // 取消收藏
    cancelFav() {
      this.favVisible = false
    },
    // 显示操作提示
    showActionSuccessNotify(data) {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.savedReportData = data
      this.actionNotifyMsg = {
        text: '生成报告成功',
        link: '点击查看',
      }
      this.actionNotifyVisible = true
      this.timer = setTimeout(() => {
        this.actionNotifyVisible = false
      }, 5e3)
    },
    // 使用文件夹id生成报告（将报告保存到该文件夹下)
    async createReportWithFolder(folder, notify = true) {
      const title = this.reportName || this.tempTitle
      const url = `${this.$base}/management/user/${this.userId}/collection`
      const contentToSave = {
        judgeReports: this.judgeList,
        contentReports: this.contentList,
        anaReports: this.anaList,
        lawReports: this.lawList,
        criterionReports: this.criterionList,
        agreementReports: this.agreementList,
        knowledgeReports: this.knowledgeList,
        textReport: this.textReport,
        judgeWords: this.judgeWords,
        lawWords: this.lawWords,
        userName: this.userName,
        lawFirmName: this.lawFirmName,
        dayTime: this.dayTime,
        hasFirstPage: this.hasFirstPage,
        avatarUrl: this.avatarUrl,
      }
      const d = {
        content: JSON.stringify(contentToSave),
        level: folder.level,
        name: title,
        size: 0,
        parentId: folder.parentId,
        type: 'SEARCHREPORT',
        createTime: new Date().getTime(),
      }
      try {
        const { data } = await this.$axios.post(url, d)
        if (data.code === 200) {
          return data.data
        } else {
          return null
        }
      } catch (err) {
        this.$notify('生成报告失败')
        console.log(err)
      }
    },
    // 获取 创建分析报告需要的id （通过收藏实现）
    async getReportSaveId() {
      console.log('searchReport/reportId')
      const { data } = await this.$axios.get(`${this.$base}/management/users/${this.userId}`)
      if (data.code === 200 && data.data) {
        return data.data.reportscollectionId || data.data.temporaryCollectionId
      } else {
        return null
      }
    },
    // 收藏组件点击 #收藏至此# 取得上级文件夹id
    async useFav(parent) {
      if (!parent) {
        return this.$notify('选择的文件夹数据存在问题')
      }
      this.favFolderId = parent.parentId
      this.favVisible = false
      const data = this.createReportWithFolder(parent)
      if (data) {
        this.savedReportData = data
        this.reportId = data.id
        // this.showActionSuccessNotify()
      }
    },
    // 增加封面
    handleAddFirstPage() {
      const lastActiveEle = document.getElementsByClassName('active-title')[0]
      if (lastActiveEle) {
        lastActiveEle.className = ''
      }
      this.hasFirstPage = true
      document.getElementById('fengmianTittle').className = 'active-title'
    },
    // 分享
    async handleShare() {
      if (!this.reportId) {
        return
      }
      const name = this.reportName || this.tempTitle
      const ua = this.ua
      let shareUrl = `https://tool.farbun.com/transform/searchReport/${this.reportId}`
      console.log(this.$base, name + 'baseurl----')
      if (this.$base === 'https://api.dev.farbun.com') {
        shareUrl = `https://dev.tool.farbun.com/transform/searchReport/${this.reportId}`
      } else if (this.$base.includes('dev')) {
        shareUrl = `https://dev.tool.farbun.com/transform/searchReport/${this.reportId}`
      } else {
        shareUrl = `https://tool.farbun.com/transform/searchReport/${this.reportId}`
      }
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFile(name, window.encodeURI(shareUrl))
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: name,
            url: window.encodeURI(shareUrl),
          })
        )
      } else {
        this.$notify('网页端暂不支持分享')
        this.copyLink(shareUrl)
      }
    },
    // 复制链接地址
    copyLink(url) {
      let text = url
      text = text.replace(/<[^>]*>/g, '')
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      // this.$message('已复制当前链接，请粘贴给需要分享的人')
    },
    // 下载
    handleDownLoad() {},
  },
}
</script>

<style lang="stylus" scope>
.head-left
  display flex
  line-height 1.173333rem;
  .user-time,.collect-box
    margin-left 10px
    font-size 12px
    cursor pointer
  .relate-case
    position absolute
    left 40px
    bottom 0
    font-size 10px
    color #ccc
    line-height 14px
.header-menu
  font-size 12px
  .menu-text
    color #409EFF
    margin-right 0.15rem
    cursor pointer
.search-report
  padding-top 40px
  .print-fengmian
    width 100vw
    height 100vh
    background #ffffff
    font-weight bold
    margin-bottom 20px
    .fengmian-logo
      width 40px
      height 40px
      // background red
    .fengmian-lawfirm
      width 100%
      margin 50px auto
      font-size 28px
      display flex
      justify-content center
      align-items center
    .fengmian-report
      width 50px
      height 160px
      margin 0 auto
      margin-top 80px
      display flex
      flex-direction column
      justify-content space-around
      align-items center
      font-size 20px
    .fengmian-userinfo
      width 90%
      margin 0 auto
      margin-top 150px
      font-weight normal
      display flex
      justify-content center
      align-items center
      font-size 20px
  .print-body
    width 100%
    min-height 100vh
    background #ffffff
    padding-bottom 40px
    .text-content
      padding 10px
    h3
      font-weight bold
      font-size 16px
      height 40px
      line-height 40px
      padding-left 10px
  & .content-item
    padding 5px 12px
    line-height 18px
    & .content-title
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  & .judgement-item,.law-item, .item
    position relative
    & .title
      width 100%
      padding 10px
      overflow hidden
      cursor pointer
      display flex
      flex-direction row
      & .text
        font-size 16px
        color #333333
        float left
        font-weight 600
        flex 1
      & .CanNot
        height 21px
        box-sizing border-box
        background #ff9c39
        color #fff
        line-height 21px
        padding 0 5px
        border-radius 3px
    & .showrow
      width 100%
      padding 10px
      padding-top 0
      overflow hidden
      & .text
        font-size 12px
        color #999999
        font-weight normal
        float left
      & .open
        float left
        font-size 12px
        color #999999
        font-weight normal
        overflow hidden
        margin-right 10px
        cursor pointer
        & i
          font-size 14px
        & .tip
          overflow hidden
          padding 3px 5px 3px
          background-color #ff9c39
          font-size 14px
          color white
          border-radius 3px
    & .zkcontent
      width 100%
      padding 0 10px
      margin-bottom 10px
      & h4
        font-size 14px
      & span
        font-size 12px
.bottom-menu
  position fixed
  width 100vw
  max-height 80vh
  left 0
  right 0
  bottom 0
  background #fff
  border-top 1px solid #ccc
  z-index 999
  overflow scroll
  .menu-content
    background #fff
    height 100%
    padding 5px
    box-sizing border-box
    & #reportBodyTitle,#fengmianTittle
      font-weight bold
      height 40px
      line-height 40px
      font-size 16px
    .report-mulu
      width 100%
      max-height 400px
      overflow-y auto
    & h3
      font-weight bold
.nav
  width 100%
  height 100%
  position absolute
  left 0px
  top 0px
  background-color #ffffff
  z-index 10000000
  overflow-x hidden
.bottom-wrapper
  position fixed
  left 0
  right 0
  bottom 0
  width 100%
  z-index 99
  height 40px
  background #fff
  .download-app
    float right
    width 60px
    height 40px
    text-align center
    line-height 40px
    color #409EFF
    cursor pointer
  .open-panel
    float left
    width 60px
    height 40px
    text-align center
    line-height 40px
    color #409EFF
    cursor pointer
.add-tag:before
  cursor pointer
  content '·'
  font-weight bold
.active-title
  color #409EFF
.full-screen-portal-comp
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  z-index 2998
</style>

<style scope lang="stylus">
.item
  position relative
  & .item-title
    width calc(100vw - 20px)
    padding 10px
    overflow hidden
    cursor pointer
    display flex
    flex-direction row
    & .text
      font-size 16px
      color #333333
      float left
      font-weight 600
      min-height 20px
      line-height 20px
      flex 1
    & .CanNot
      height 21px
      box-sizing border-box
      background #ff9c39
      color #fff
      line-height 21px
      padding 0 5px
      border-radius 3px
  & .showrow
    width 100%
    padding 10px
    padding-top 0
    overflow hidden
    & .text
      font-size 12px
      color #999999
      font-weight normal
      float left
    & .open
      float left
      font-size 12px
      color #999999
      font-weight normal
      overflow hidden
      margin-right 10px
      cursor pointer
      & i
        font-size 14px
      & .tip
        overflow hidden
        padding 3px 5px 3px
        background-color #ff9c39
        font-size 14px
        color white
        border-radius 3px
  & .zkcontent
    width 100%
    padding 0 10px
    margin-bottom 10px
    & h4
      font-size 14px
    & span
      font-size 12px
& .more
  color #666666
  font-size 16px
  text-align center
  margin 20px 0px 20px 0px
</style>
